/** @jsx jsx */
import { Themed } from "@theme-ui/mdx"
import MarkdownJsx from "markdown-to-jsx"
import { node, string } from "prop-types"
import { jsx } from "theme-ui"

import Link from "./Link"

const MarkdownLink = ({ children, href }) => (
  <Link
    aria-label={href}
    sx={{
      color: "#ea2d16",
      fontWeight: "600",
      fontFamily: "heading",
      fontSize: [2, null, 3],
      lineHeight: "1.5",
    }}
    to={href}
  >
    {children}
  </Link>
)

MarkdownLink.propTypes = {
  children: node.isRequired,
  href: string.isRequired,
}

const MarkdownP = ({ children }) => (
  <Themed.p
    sx={{
      margin: "20px 0",
    }}
  >
    {children}
  </Themed.p>
)

MarkdownP.propTypes = {
  children: node.isRequired,
}

const Strong = ({ children }) => (
  <strong sx={{ fontWeight: "600" }}>{children}</strong>
)

Strong.propTypes = {
  children: node.isRequired,
}

const Markdown = ({ md }) => (
  <div
    sx={{
      "h1, h2, h3": {
        marginTop: "40px",
      },
      h4: {
        marginTop: "20px",
      },
      "p, li, i span, code, blockquote": {
        fontSize: [2, null, 3],
        lineHeight: "1.5",
        fontWeight: "300",
      },
      fontFamily: "GothamNarrow",
      "*": {
        maxWidth: "100%",
      },
    }}
  >
    <MarkdownJsx
      options={{
        overrides: {
          a: MarkdownLink,
          p: MarkdownP,
          span: MarkdownP,
          strong: Strong,
          h1: Themed.h1,
          h2: Themed.h2,
          h3: Themed.h3,
          h4: Themed.h4,
        },
      }}
    >
      {md}
    </MarkdownJsx>
  </div>
)

Markdown.propTypes = {
  md: string.isRequired,
}

export default Markdown
