import {
  faAngleDoubleRight,
  faAngleDown,
  faLongArrowAltRight,
  faPlay,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"

import IconWrapper from "./IconWrapper"

export { default as CircleArrow } from "./CircleArrow"

const Arrow = IconWrapper(faLongArrowAltRight)
const AngleDoubleRight = IconWrapper(faAngleDoubleRight)
const AngleDown = IconWrapper(faAngleDown)
const Cross = IconWrapper(faTimes)
const Play = IconWrapper(faPlay)

export { Arrow, AngleDoubleRight, AngleDown, Cross, Play }
