/** @jsx jsx */
import { Themed } from "@theme-ui/mdx"
import { string } from "prop-types"
import { Fragment } from "react"
import { jsx } from "theme-ui"

const styles = {
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "#ffffff",
    margin: ["20px 0", null, null, "0 20px"],
  },
  item: {
    marginTop: "15px",
    letterSpacing: "0.5px",
  },
}

const ContactInfo = ({
  contactTitle,
  email,
  phoneNumber,
  address,
  addressTitle,
  zipCode,
}) => (
  <Fragment>
    <div sx={styles.flexWrapper}>
      <Themed.h4>{addressTitle}</Themed.h4>
      <Themed.p as="span" sx={styles.item}>
        {address}
      </Themed.p>
      <Themed.p as="span" sx={styles.item}>
        {zipCode}
      </Themed.p>
    </div>
    <div sx={styles.flexWrapper}>
      <Themed.h4>{contactTitle}</Themed.h4>
      <Themed.p as="a" href={`tel:${phoneNumber}`} sx={styles.item}>
        {phoneNumber}
      </Themed.p>
      <Themed.p as="a" href={`mailto:${email}`} sx={styles.item}>
        {email}
      </Themed.p>
    </div>
  </Fragment>
)

ContactInfo.propTypes = {
  address: string.isRequired,
  addressTitle: string.isRequired,
  contactTitle: string.isRequired,
  email: string.isRequired,
  phoneNumber: string.isRequired,
  zipCode: string.isRequired,
}

export default ContactInfo
