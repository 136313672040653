/** @jsx jsx */

import { globalHistory } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import { func } from "prop-types"
import { useRef, useState } from "react"
import { jsx } from "theme-ui"

import useOutsideClick from "../../hooks/useOutsideClick"
import { AngleDown } from "../Icons"
import Link from "../Link"

const getStyles = isOpen => ({
  a: {
    borderRadius: "5px",
    color: "#000",
    fontWeight: "300",
    fontSize: "16px",
    padding: "15px",
    display: "block",

    ":hover": {
      backgroundColor: "#f3f3f3",
    },
  },
  active: {
    fontWeight: "bold",
    pointerEvents: "none",
  },
  button: {
    color: "#2b2b2b",
    fontSize: [1, null, 2],
    fontWeight: "500px",
    letterSpacing: "0.49px",
    fontFamily: "heading",
    backgroundColor: "transparent",
    padding: "0px",
    width: "max-content",
    whiteSpace: "nowrap",
    border: "none",
    textTransform: "uppercase",
    cursor: "pointer",
    position: "relative",

    "::before": {
      content: "''",
      display: "block",
      position: "absolute",
      width: "0",
      height: "4px",
      borderRadius: "2px",
      left: "0",
      bottom: "-3px",
      backgroundColor: "red",
      transition: "width 0.3s ease",
    },
    ":hover": {
      "::before": {
        width: "40px",
      },
    },
  },
  div: {
    position: "relative",
  },
  activeStyle: {
    "&::before": {
      width: "40px",
    },
  },
  icon: {
    marginLeft: ["5px", null, "10px"],
    transition: "transform 0.3s ease",
    transform: "rotate(0deg)",
    position: [null, "relative", "static"],
    top: "1px",
  },
  rotate: {
    transform: "rotate(180deg)",
  },
  ul: {
    display: "flex",
    visibility: isOpen ? "visible" : "hidden",
    boxShadow: ["initial", "standard"],
    borderRadius: "5px",
    fontFamily: "GothamPro",
    opacity: isOpen ? "1" : "0",
    backgroundColor: ["transparent", "#fff"],
    maxHeight: [`${isOpen ? "100%" : "0"}`, "initial"],
    width: "200px",
    position: ["static", "absolute"],
    flexDirection: "column",
    marginTop: ["5px", "0"],
    padding: [`${isOpen ? "5px 0" : "0"}`, "15px"],
    top: "30px",
    zIndex: "10",
    listStyle: "none",
    left: "10px",
    transition: "visibility 0s, opacity 0.3s linear",
  },
})

const Dropdown = ({ onNavigation }) => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsAboutDepot {
        title
        slug
        menuItems {
          id
          pageTitle
          slug
        }
      }
    }
  `)

  const ref = useRef()
  const [isOpen, setIsOpen] = useState(false)

  const close = () => setIsOpen(false)
  const toggle = () => setIsOpen(open => !open)

  const handleClick = () => {
    if (onNavigation) {
      onNavigation()
    }
    close()
  }

  useOutsideClick(ref, close)
  const { a, active, button, div, rotate, icon, ul, activeStyle } = getStyles(
    isOpen
  )

  const { title, slug, menuItems } = data.datoCmsAboutDepot
  let isActive = false

  globalHistory.location.pathname.split("/").forEach(path => {
    if (slug === path) isActive = true
  })

  const renderMenuItems = menuItems.map(item => (
    <li key={item.id}>
      <Link
        activeStyle={active}
        aria-label={item.pageTitle}
        onClick={handleClick}
        sx={a}
        to={item.slug}
      >
        {item.pageTitle}
      </Link>
    </li>
  ))

  return (
    <div ref={ref} sx={div}>
      <button
        onClick={toggle}
        sx={{ ...button, ...(isActive ? activeStyle : {}) }}
      >
        <span>{title}</span>
        <AngleDown sx={{ ...icon, ...(isOpen && rotate) }} />
      </button>

      <ul sx={ul}>{renderMenuItems}</ul>
    </div>
  )
}

Dropdown.defaultProps = {
  onNavigation: null,
}

Dropdown.propTypes = {
  onNavigation: func,
}

export default Dropdown
