exports.components = {
  "component---src-pages-aktuellt-js": () => import("./../../../src/pages/aktuellt.js" /* webpackChunkName: "component---src-pages-aktuellt-js" */),
  "component---src-pages-bilder-js": () => import("./../../../src/pages/bilder.js" /* webpackChunkName: "component---src-pages-bilder-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-depan-js": () => import("./../../../src/pages/om-depan.js" /* webpackChunkName: "component---src-pages-om-depan-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-timeline-js": () => import("./../../../src/templates/timeline.js" /* webpackChunkName: "component---src-templates-timeline-js" */)
}

