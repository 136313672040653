/** @jsx jsx */

import { string } from "prop-types"
import { jsx } from "theme-ui"

import { Arrow } from "./index"

const CircleArrow = ({ variant }) => (
  <div
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "3px solid transparent",
      width: ["60px", null, "66px"],
      height: ["40px", null, "45px"],
      borderRadius: "25px",
      variant: `links.${variant}`,
      transition: [null, null, "transform 0.3s ease"],
    }}
  >
    <Arrow
      sx={{
        variant: `links.${variant}.color`,
        display: "block",
        transition: [null, null, "transform 0.3s ease"],
      }}
    />
  </div>
)

CircleArrow.defaultProps = {
  variant: "primary",
}

CircleArrow.propTypes = {
  variant: string,
}

export default CircleArrow
