import "@fortawesome/fontawesome-svg-core/styles.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"

export default function IconWrapper(Icon) {
  // eslint-disable-next-line
  return function(props) {
    return <FontAwesomeIcon {...props} icon={Icon} />
  }
}

IconWrapper.propTypes = {
  icon: PropTypes.any.isRequired,
}
