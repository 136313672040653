/** @jsx jsx */

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { jsx } from "theme-ui"

import Link from "../Link"
import Markdown from "../Markdown"
import ContactInfo from "./ContactInfo"

const styles = {
  footer: {
    backgroundColor: "#41403d",
    textAlign: "center",
    paddingTop: "60px",
    paddingBottom: "60px",
    paddingLeft: ["30px", "48px", "15%"],
    paddingRight: ["30px", "48px", "15%"],
    width: "100%",
    display: "flex",
    flexDirection: ["column", null, null, "row"],
    justifyContent: ["center", null, null, "space-between"],
    alignItems: "center",
  },
  logoWrapper: {
    margin: ["20px 0", null, null, "0 20px"],
    minWidth: "260px",
    maxWidth: "300px",
    order: ["none", "", "", "-1"],
  },
  logoText: {
    color: "#ffffff",
    marginTop: "15px",
  },
  divider: {
    width: "1px",
    height: "200px",
    border: "solid 1px #ffffff",
    order: ["none", "", "", "-1"],
    display: ["none", "", "", "block"],
  },
  companyLogo: {
    width: "100%",
    height: "100%",
  },
}

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsCompanyInfo {
        url
        id
        info
        image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      datoCmsContactInfo {
        contactTitle
        email
        phoneNumber
        address
        addressTitle
        zipCode
      }
    }
  `)

  const { image, info, url, id } = data.datoCmsCompanyInfo

  const { datoCmsContactInfo } = data

  return (
    <footer sx={styles.footer}>
      <ContactInfo {...datoCmsContactInfo} />
      <div sx={styles.logoWrapper}>
        {image && (
          <Link aria-label={image.alt} sx={styles.companyLogo} to={url}>
            <GatsbyImage
              alt={image?.alt ?? ""}
              image={image.gatsbyImageData}
              key={id}
              loading="eager"
            />
          </Link>
        )}
        <div sx={styles.logoText}>
          <Markdown md={info} />
        </div>
      </div>
      <div sx={styles.divider} />
    </footer>
  )
}

export default Footer
