/** @jsx jsx */
import { Link as GatsbyLink } from "gatsby"
import { bool, node, object, string } from "prop-types"
import { jsx } from "theme-ui"

const Link = ({
  children,
  to,
  activeStyle,
  activeClassName,
  partiallyActive,
  ...other
}) => {
  const internal = /^\/(?!\/)/.test(to)
  if (internal) {
    return (
      <GatsbyLink
        activeClassName={activeClassName}
        activeStyle={activeStyle}
        partiallyActive={partiallyActive}
        to={to}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  )
}

Link.defaultProps = {
  activeClassName: "",
  activeStyle: null,
  partiallyActive: false,
}

Link.propTypes = {
  activeClassName: string,
  activeStyle: object,
  children: node.isRequired,
  partiallyActive: bool,
  to: string.isRequired,
}

export default Link
