/** @jsx jsx */
import { Themed } from "@theme-ui/mdx"
import { Fragment } from "react"
import { jsx } from "theme-ui"

import Link from "../Link"
import Nav from "./Nav"
import Partners from "./Partners"

const Navigation = () => (
  <Fragment>
    <Partners />
    <header
      sx={{
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: ["space-around", "space-between"],
        alignItems: "center",
        width: "100%",
        backgroundColor: "#f3f3f3",
        paddingLeft: ["10px", "60px"],
        paddingRight: ["10px", "60px", null, "150px"],
        paddingTop: [null, null, "20px"],
        height: "75px",
        position: "sticky",
        top: "0",
        zIndex: "100",
      }}
    >
      <Link aria-label="Hässleholmsdepån" to="/">
        <Themed.h2
          sx={{
            color: "#d51e08",
            fontWeight: "600",
            letterSpacing: "initial",
            fontSize: ["22px", "22px", 4, 5],
            marginTop: ["5px", "0"],
          }}
        >
          Hässleholmsdepån
        </Themed.h2>
      </Link>
      <Nav />
    </header>
  </Fragment>
)

export default Navigation
