/** @jsx jsx */

import { bool, func } from "prop-types"
import { jsx } from "theme-ui"

const Hamburger = ({ active, handleClick }) => (
  <button
    aria-label="Open the menu"
    onClick={handleClick}
    sx={{
      display: "flex",
      justifyContent: "center",
      border: "0",
      margin: "0px",
      backgroundColor: "transparent",
      cursor: "pointer",
      width: "52px",
      height: "38px",
      position: "relative",
    }}
    type="button"
  >
    <span
      sx={{
        display: "block",
        width: "30px",
        height: "2px",
        backgroundColor: "#d51e08",
        borderRadius: "4px",
        position: "absolute",
        top: "auto",
        bottom: "7px",
        transitionDuration: "0.13s",
        transitionDelay: active ? "0.22s" : "0.13s",
        transitionTimingFunction: `cubic-bezier(${active} ? "0.215, 0.61, 0.355, 1" : "0.55, 0.055, 0.675, 0.19")`,
        transform: active ? "translate3d(0, -10px, 0) rotate(-45deg)" : "none",

        "::before": {
          display: "block",
          width: "30px",
          height: "2px",
          backgroundColor: "#d51e08",
          borderRadius: "4px",
          position: "absolute",
          content: '""',
          top: active ? "0" : "-10px",
          transition: active
            ? `top 0.1s 0.16s
                  cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1)`
            : `top 0.12s 0.2s
                  cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
          transform: `rotate(${active ? "-90deg" : "0"})`,
        },

        "::after": {
          display: "block",
          width: "30px",
          height: "2px",
          backgroundColor: "#d51e08",
          borderRadius: "4px",
          position: "absolute",
          content: '""',
          opacity: active ? "0" : "1",
          top: active ? "0" : "-20px",
          transition: active
            ? `top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                opacity 0.1s 0.22s linear`
            : `top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                opacity 0.1s linear`,
        },
      }}
    />
  </button>
)

Hamburger.propTypes = {
  active: bool.isRequired,
  handleClick: func.isRequired,
}

export default Hamburger
