/** @jsx jsx */

import { graphql, useStaticQuery } from "gatsby"
import { Fragment, useEffect, useLayoutEffect, useReducer, useRef } from "react"
import { jsx } from "theme-ui"

import Link from "../Link"
import Dropdown from "./Dropdown"
import Hamburger from "./Hamburger"

const getStyles = hamburgerIsOpen => ({
  navItems: {
    position: ["fixed", "initial"],
    height: [null, "100%"],
    width: ["100vw", "100%"],
    bottom: "0",
    top: "0",
    left: "100%",
    minWidth: "320px",
    transform: [
      `translateX(${hamburgerIsOpen ? "-100%" : "0%"})`,
      null,
      "unset",
    ],
    transition: "transform 0.3s ease",
    display: "grid",
    gridTemplateColumns: [`fit-content(250px)`, "unset"],
    gridAutoFlow: [null, "column"],
    justifyContent: ["center", "space-between"],
    gridGap: ["30px", "0px"],
    alignContent: "center",
    backgroundColor: ["#fbecea", "transparent"],
    padding: ["65px 30px 30px 30px", "0"],
    listStyle: "none",
    margin: "0",
  },
  navItem: {
    margin: ["0 5px 5px 5px", "0 10px", "0 15px"],
  },
  navLinks: {
    padding: ["2px 0", "5px 0"],
    color: "#2b2b2b",
    fontSize: [1, null, 2],
    fontWeight: "500",
    letterSpacing: "0.49px",
    fontFamily: "heading",
    textTransform: "uppercase",
    position: "relative",
    "::before": {
      content: "''",
      display: "block",
      position: "absolute",
      width: "0",
      height: "4px",
      borderRadius: "2px",
      left: "0",
      bottom: "-3px",
      backgroundColor: "red",
      transition: "width 0.3s ease",
    },
    ":hover": {
      "::before": {
        width: "40px",
      },
    },
    "&.active": {
      "::before": {
        width: "40px",
      },
    },
  },
  hamburger: {
    display: [null, "none"],
    zIndex: "200",
  },
})

const initialState = {
  hamburgerIsOpen: false,
  dropdownIsOpen: false,
}

const types = {
  TOGGLE_HAMBURGER: "TOGGLE_HAMBURGER",
  CLOSE_HAMBURGER: "CLOSE_HAMBURGER",
}

const { TOGGLE_HAMBURGER, CLOSE_HAMBURGER } = types

function reducer(state, action) {
  switch (action.type) {
    case TOGGLE_HAMBURGER:
      return {
        ...state,
        hamburgerIsOpen: !state.hamburgerIsOpen,
      }
    case CLOSE_HAMBURGER:
      return {
        ...state,
        hamburgerIsOpen: false,
      }
    default:
      throw new Error()
  }
}

const Nav = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { hamburgerIsOpen } = state

  const burger = useRef()

  const toggle = () => dispatch({ type: TOGGLE_HAMBURGER })
  const close = () => dispatch({ type: CLOSE_HAMBURGER })

  useEffect(() => {
    let resizeTimer
    window.addEventListener("resize", () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        if (window.innerWidth >= 768) {
          close()
        }
      }, 250)
    })

    return () => {
      window.removeEventListener("resize", close)
    }
  }, [hamburgerIsOpen])

  useLayoutEffect(() => {
    let overflowValue
    if (hamburgerIsOpen) {
      overflowValue = "hidden"
    } else {
      overflowValue = ""
    }

    document.body.style.overflow = overflowValue
  }, [hamburgerIsOpen])

  const { navItems, navItem, navLinks, hamburger, activeStyle } = getStyles(
    hamburgerIsOpen
  )

  const data = useStaticQuery(graphql`
    query {
      datoCmsNavigation {
        navigation {
          id
          slug
          title
        }
      }
      datoCmsAboutDepot {
        slug
      }
    }
  `)

  const { navigation } = data.datoCmsNavigation
  const { slug: aboutDepotSlug } = data.datoCmsAboutDepot

  return (
    <Fragment>
      <nav>
        <ul sx={navItems}>
          {navigation.map(({ title, slug, id }) => (
            <li key={id} sx={navItem}>
              {slug === aboutDepotSlug ? (
                <Dropdown onNavigation={close} />
              ) : (
                <Link
                  activeClassName="active"
                  activeStyle={activeStyle}
                  aria-label={slug}
                  onClick={close}
                  partiallyActive
                  sx={navLinks}
                  to={`/${slug}`}
                >
                  {title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div ref={burger} sx={hamburger}>
        <Hamburger active={hamburgerIsOpen} handleClick={toggle} />
      </div>
    </Fragment>
  )
}

export default Nav
