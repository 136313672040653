/** @jsx jsx */

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { jsx } from "theme-ui"

import Link from "../Link"

const styles = {
  partners: {
    display: "flex",
    width: "100%",
    height: "50px",
    justifyContent: "center",
    alignItems: "center",
  },
}

const Partners = () => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsNavigation {
        url
        companyLogo {
          gatsbyImageData(width: 160, height: 20, layout: FIXED)
          alt
          originalId
        }
      }
    }
  `)

  const { companyLogo, url } = data.datoCmsNavigation

  return (
    <div sx={styles.partners}>
      {companyLogo && (
        <Link aria-label={companyLogo.alt} to={url}>
          <GatsbyImage
            alt={companyLogo.alt ?? ""}
            image={companyLogo.gatsbyImageData}
            key={companyLogo.originalId}
            loading="eager"
          />
        </Link>
      )}
    </div>
  )
}

export default Partners
